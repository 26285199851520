import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { Order } from '@datorama/akita';
import { addDays, isSameDay, isValid } from 'date-fns/esm';
import { merge } from 'lodash-es';
import { ComegoQuery, ComegoTime, ComegoTimeType, UserSettings } from 'timeghost-api';
import { evaluateWorkingHours } from './rulebreakUtil';
type CCSumBag = Record<ComegoTimeType | 'workUntilStart' | 'pauseUntilStart', number>;
function getLastTime(user: UserSettings, date?: Date, type?: ComegoTimeType) {
  const comegoQuery = inject(ComegoQuery);
  if (!date || !isValid(date)) return null;
  const pastDay = addDays(date.getTime(), -1);
  const lastWorkingDayTime = comegoQuery.getAll({
    filterBy: (x) => isSameDay(new Date(x.start), pastDay) && x.user?.id === user.id && (!type || x.type === type),
    limitTo: 1,
    sortBy(a, b) {
      const aend = new Date(a.end || new Date()),
        bend = new Date(b.end || new Date());
      return aend.getTime() - bend.getTime();
    },
    sortByOrder: Order.DESC,
  })?.[0];
  if (!lastWorkingDayTime) return null;
  return {
    ...lastWorkingDayTime,
    start: new Date(lastWorkingDayTime.start),
    end: new Date(lastWorkingDayTime.end || new Date()),
  };
}
function getNextTime(user: UserSettings, date?: Date, type?: ComegoTimeType) {
  const comegoQuery = inject(ComegoQuery);
  if (!date || !isValid(date)) return null;
  const dayOfTime = addDays(date.getTime(), 1);
  const nextWorkingDayTime = comegoQuery.getAll({
    filterBy: (x) => isSameDay(new Date(x.start), dayOfTime) && x.user?.id === user.id && (!type || x.type === type),
    limitTo: 1,
    sortBy(a, b) {
      const atime = new Date(a.start || new Date()),
        btime = new Date(b.start || new Date());
      return atime.getTime() - btime.getTime();
    },
    sortByOrder: Order.ASC,
  })?.[0];
  if (!nextWorkingDayTime) return null;
  return {
    ...nextWorkingDayTime,
    start: new Date(nextWorkingDayTime.start),
    end: new Date(nextWorkingDayTime.end || new Date()),
  };
}
const typeToTranslateKey = {
  maxHours: 'errors.times.comego.dailyMaxHours-alert',
  break: 'errors.times.comego.breakRuleNotice-alert',
  rest: 'errors.times.comego.breakBetweenWorkingDays-alert',
};
export type CheckWorkingHourErrorsOptions = {
  prevDayRestEnabled: boolean;
  nextDayRestEnabled: boolean;
  breakBetweenDaysTypeFilter: ComegoTimeType;
  recordingTimeDiffDateFallback: boolean;
};
export function checkWorkingHourErrors(
  this: EnvironmentInjector,
  times: ComegoTime[],
  user: UserSettings,
  date?: Date,
  errorActAsWarning?: boolean,
  options?: Partial<CheckWorkingHourErrorsOptions>,
) {
  const wh = user?.workspace?.schedules?.workspace?.workinghours;
  if (!wh) return null;
  if (!date) date = new Date();
  options = merge(
    { nextDayRestEnabled: true, prevDayRestEnabled: true } as CheckWorkingHourErrorsOptions,
    options || {},
  );
  errorActAsWarning = !!errorActAsWarning;
  return evaluateWorkingHours(times, wh, date, {
    nextDayItem:
      options.nextDayRestEnabled && runInInjectionContext(this, () => getNextTime(user, date, 'work') as any),
    prevDayItem:
      options.prevDayRestEnabled && runInInjectionContext(this, () => getLastTime(user, date, 'work') as any),
  });
}
